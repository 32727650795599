import LoanList from "./LoanList";
import LoanPost from "./LoanPost";
import LoanEdit from "./LoanEdit";

const Loan = {
  list: LoanList,
  create: LoanPost,
  edit: LoanEdit,
}

export default Loan;