import AppAdmin from "./components/Admin";
import './App.css';

function App() {
  return (
    <AppAdmin/>
  );
}

export default App;
